// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`..
const local = "https://api.prizmaventas.com/";
const remoto = "https://api.prizmaventas.com/";
const remoto_dev = "https://api.prizmaventas.com/";


export const environment = {
  production: false,
  googleAnalyticsId: 'UA-153412729-1',
  baseUrl:remoto_dev,
  firebaseConfig : {
    apiKey: "AIzaSyC77kVfahQPdfQcBPF-PrO__qsFT_GuT-E",
    authDomain: "daclick-49712.firebaseapp.com",
    databaseURL: "https://daclick-49712-default-rtdb.firebaseio.com",
    projectId: "daclick-49712",
    storageBucket: "daclick-49712.appspot.com",
    messagingSenderId: "378048540331",
    appId: "1:378048540331:web:68b1818cfba6bb40e811ee",
    measurementId: "G-WDPSVX8L80"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
